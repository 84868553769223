.mission-page-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  align-items: center;
}

.hr-block {
  width: 75px;
  height: 5px;
  background-color: #afafaf;
}

.mission-page-wrapper > .container.inner-container {
  flex-grow: initial;
  max-width: 600px;
}

.inner-container > p {
  margin-bottom: 15px;
}

.back-button {
  align-self: start;
}

.emailContainer {
  background: #f6f6f6;
  padding: 10px 20px;
}

.emailContainer>p {
  margin-bottom: 16px;
}

.buttonContainer > span {
  position: absolute;
  transition: all 0.3s;
  top: -15px;
  opacity: 0;
}

span.clipboard-success {
  top: -20px;
  opacity: 1;
}

span.clipboard-action {
  top: -20px;
  opacity: 1;
}
