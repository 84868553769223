.scrollTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTopButton:hover {
  opacity: 1;
}

.scroll-icon {
  color: #30839f;
  font-size: 50px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
