.donate-page-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  align-items: center;
}

.hr-block {
  width: 75px;
  height: 5px;
  background-color: #afafaf;
}

.donate-page-wrapper > .container.inner-container {
  flex-grow: initial;
  max-width: 600px;
}

.inner-container > p {
  margin-bottom: 15px;
}

.back-button {
  align-self: start;
}

.donor-box-container {
  justify-content: center;
  display: flex;
  margin-top: 40px;
}

.donorbox-custom-amount .custom-amount-input {
  color: #4C4C4C;
}
