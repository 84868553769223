.max-width {
  max-width: 85%;
}

.lockdown-header {
  margin-bottom: 1.5rem;
}

.hero-logo {
  width: 26px;
  margin-right: 10px;
}

h3.title {
  font-size: 1.5rem;
}

.disclaimer-button {
  border-color: #c5c5c5;
}
.disclaimer-button:hover {
  border-color: #afafaf;
}

.section p {
  font-size: 1rem;
}

.tag {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.container .tag.inactive {
  background-color: rgba(218, 223, 225, 0.8);
  color: #804a4a4a;
}

.container .research-tag {
  background-color: white;
}
.container .expert-tag {
  background-color: #d2f8d2;;
}
.container .data-tag {
  background-color: #e5f8ff;
}
.container .discussion-tag {
  background-color: #fffff2;
}
.container .leader-tag {
  background-color: #eee8f3;
}
.container .consequence-tag {
  background-color: #ffcccc;
}

.expert {
  background-color: #d2f8d2;
}
.data {
  background-color: #e5f8ff;
}
.discussion {
  background-color: #fffff2;
}
.leader {
  background-color: #eee8f3;
}
.consequence {
  background-color: #ffcccc;
}

.article-wrapper {
  /* max-width: 640px; */
  margin-bottom: 1.5rem;
}

.container .trans-button {
  background: transparent;
}
.link-icon {
  margin-right: 5px;
  color: #363636;
}

@media screen and (max-width: 900px) {
  .max-width {
    max-width: 100%;
    padding: 0 1.5rem;
  }
}
